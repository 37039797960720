import * as React from "react"
import { PageProps, graphql } from "gatsby"
import Seo from "gatsby-plugin-wpgraphql-seo"

import Layout from "../layout"
import Services from "../organisms/services"
import Heading from "../organisms/heading"

type DataProps = {
  wpPage: {
    seo: any
    hero_section: any
  }
  allWpService: { nodes: any[] }
}

const ServicesPage: React.FC<PageProps<DataProps>> = ({ data }) => {
  const { hero_section } = data.wpPage
  const allServices = data.allWpService.nodes

  return (
    <Layout>
      <Seo post={data.wpPage} />
      <Heading data={hero_section} />
      <Services
        data={{
          services: allServices,
        }}
      />
    </Layout>
  )
}

export const query = graphql`
  query ServicesPageQuery($id: String) {
    wpPage(id: { eq: $id }) {
      nodeType
      title
      uri
      ...heroPageFields
      ...seoPagesFields
    }
    allWpService {
      nodes {
        uri
        title
        hero_section {
          subtitle
        }
        postTypeService {
          global {
            icon {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: TRACED_SVG
                    formats: AUTO
                    pngOptions: { compressionSpeed: 10, quality: 85 }
                    width: 45
                    layout: CONSTRAINED
                  )
                }
              }
            }
            thumbnail {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: DOMINANT_COLOR
                    formats: AUTO
                    pngOptions: { compressionSpeed: 10, quality: 85 }
                    jpgOptions: { quality: 85 }
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ServicesPage
